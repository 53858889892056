import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as menuRedux from '@app/redux/models/menu';

import { Button } from 'antd';

import { injectIntl } from 'react-intl';
import { MobileDropdown } from './MobileDropdown';

const MobilePager = (props) => {
  const [menuHidden, setMenuHidden] = useState(props.menuIsCollapsed);
  const [redux_events, setReduxEvents] = useState(props.redux_events);
  const [title, setTitle] = useState(props.title);
  const [backButton, setBackButton] = useState(typeof props.backButton === 'function');
  const [showMenu, setShowMenu] = useState(props.showMenu);
  const [show3Dots, setShow3Dots] = useState(typeof props.showThreeDots === 'function');

  useEffect(() => {
    if (!redux_events) return;
    setTitle(props.currentPage.title);
    setBackButton(typeof props.currentPage.backButton === 'function');
    setShowMenu(props.currentPage.showMenu);
    setShow3Dots(typeof props.showThreeDots === 'function');
  }, [props.currentPage, props.showThreeDots, redux_events]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setBackButton(typeof props.backButton === 'function');
  }, [props.backButton]);

  useEffect(() => {
    setShowMenu(props.showMenu);
  }, [props.showMenu]);

  useEffect(() => {
    setMenuHidden(props.menuIsCollapsed);
  }, [props.menuIsCollapsed]);

  let my_button = (
    <Button
      icon="menu"
      type="link"
      block
      className="backButton"
      onClick={() => props.collapseMenu(!props.menuIsCollapsed)}
    />
  );

  if (!redux_events && backButton)
    my_button = (
      <Button
        icon="left"
        type="link"
        block
        className="backButton"
        onClick={() => props.backButton()}
      />
    );

  if (redux_events && props.currentPage && props.currentPage.backButton)
    my_button = (
      <Button
        icon="left"
        type="link"
        block
        className="backButton"
        onClick={() => props.currentPage.backButton()}
      />
    );

  const my_right_btn =
    props.currentPage && typeof props.currentPage.showThreeDots === 'function' ? (
      <Button icon="more" type="link" block onClick={() => props.currentPage.showThreeDots()} />
    ) : null;

  return (
    <div className="mobile_pager">
      <div className="left_buttons">{my_button}</div>
      <div className="title">{title}</div>
      <div className="right_buttons">
        <MobileDropdown />
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state) => ({
      isMobile: menuRedux.isMobile(state),
      menuIsCollapsed: menuRedux.isCollapsed(state),
      currentPage: menuRedux.currentPage(state),
    }),
    (dispatch) => ({
      collapseMenu: bindActionCreators(menuRedux.collapseMenu, dispatch),
    })
  )(injectIntl(MobilePager))
);
