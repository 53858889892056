import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import * as menuRedux from '@app/redux/models/menu';
import * as loginRedux from '@app/redux/models/login';
import * as siteRedux from '@app/redux/models/site';

import { Menu, Icon } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as routes_config from '@app/configs/routes';

import { getRootKeys } from '@app/services/routes';
import InjectMessage from '@app/components/intl-messages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MenuByRole = ({
  area,
  fileName,
  itemPath,
  items = [],
  getMenu,
  accountName,
  accountType,
  lastRootMenu,
  menuIsCollapsed,
  collapseMenu,
  isMobile,
  bucketTypes,
}) => {
  const [myAccountName, setAccountName] = useState(null);
  const [myAcountType, setAcountType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (myAcountType != accountType && myAccountName != accountName) {
      setAccountName(accountName);
      setAcountType(accountType);
      getMenu(accountName, accountType);
    }
  }, [myAcountType, accountType, myAccountName, accountName, getMenu]);

  const bucketRoutes = useMemo(() => {
    return bucketTypes?.reduce((acc, { key, name }) => {
      acc.push(
        {
          key,
          title: name,
          path: `/${key}/list`,
          icon: 'container',
        },
        {
          key: `create-${key}`,
          title: `crear ${name}`,
          path: `/${key}/create`,
          icon: 'plus-square',
        }
      );
      return acc;
    }, []);
  }, [bucketTypes]);

  let selected = routes_config.getItemByAreaNFilename(area, fileName, itemPath);

  if ((!selected || selected.father_key === '*' || area === 'common') && lastRootMenu) {
    const path_parts = lastRootMenu.split('/');
    const area = path_parts[1];
    const path = path_parts[2];
    selected = routes_config.getItemByFullpath(area, path, null);
  }

  const selectedBucketRoute = bucketRoutes.find(({ path }) => path === location.pathname)?.key;
  const defaultKeySelected = selectedBucketRoute
    ? [selectedBucketRoute]
    : selected
    ? [selected.key]
    : ['dashboard'];
  const bb = menuIsCollapsed ? [] : getRootKeys(accountType);

  const getIcon = (item) => {
    if (item.icon) return <Icon type={item.icon} />;
    if (item.fa_icon) return <FontAwesomeIcon icon={item.fa_icon} />;
    return false;
  };

  const renderItem = (item) => {
    if (item?.items) {
      return (
        <Menu.SubMenu
          title={
            <span>
              {getIcon(item)}
              <span>
                <InjectMessage id={item.title} defaultMessage={item.title} />
              </span>
            </span>
          }
          key={item.key}
        >
          {item.items.map(renderItem)}
        </Menu.SubMenu>
      );
    } else if (item) {
      const divider = item.bottom_divider ? <Menu.Divider /> : null;
      return [
        <Menu.Item
          key={item.key}
          disabled={item.path != item.key}
          className={item.icon || item.fa_icon ? 'is_root_menu' : ''}
        >
          <Link to={routes_config.getPath(item.path || item.key)}>
            {getIcon(item)}
            <span>
              <InjectMessage id={item.title} defaultMessage={item.title} />
            </span>
          </Link>
        </Menu.Item>,
        divider,
      ];
    }
  };

  const onMenuSelect = (e) => {
    if (isMobile) collapseMenu(true);
  };
  return (
    <Menu
      onSelect={onMenuSelect}
      defaultSelectedKeys={defaultKeySelected}
      defaultOpenKeys={bb}
      mode="inline"
      theme="light"
    >
      {items.map(renderItem)}
      <Menu.Divider />
      {bucketRoutes?.map(({ key, title, path, icon }) => (
        <Menu.Item key={key} className={'is_root_menu'}>
          <Link to={path}>
            {getIcon({ icon })}
            <span>{title}</span>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default connect(
  (state) => ({
    items: menuRedux.getMenuItems(state),
    accountName: loginRedux.accountName(state),
    accountType: loginRedux.accountType(state),
    isMobile: menuRedux.isMobile(state),
    lastRootMenu: menuRedux.lastRootMenu(state),
    menuIsCollapsed: menuRedux.isCollapsed(state),
    bucketTypes: siteRedux.bucketTypes(state),
  }),
  (dispatch) => ({
    getMenu: bindActionCreators(menuRedux.getMenu, dispatch),
    collapseMenu: bindActionCreators(menuRedux.collapseMenu, dispatch),
  })
)(MenuByRole);
