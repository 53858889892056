import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as loginRedux from '@app/redux/models/login';
import { useIntl } from 'react-intl';
import { Button, Icon, Dropdown, Menu, Modal } from 'antd';
import NotificationWidget from '@app/components/Header/NoticeIconView';
import SelectLanguage from '@app/components/Header/SelectLang';

export const MobileDropdown = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleLogout = () => {
    Modal.confirm({
      title: formatMessage({ id: 'global.logout_title' }),
      content: formatMessage({ id: 'global.logout_message' }),
      onOk() {
        dispatch(loginRedux.logout());
      },
      onCancel() {},
    });
  };

  const Overlay = (
    <Menu
      onClick={(e) => {
        e.key === '2' ? setDropdownVisible(true) : setDropdownVisible(false);
      }}
    >
      <Menu.Item key="1">
        <NotificationWidget text={formatMessage({ id: 'component.globalHeader.notification' })} />
      </Menu.Item>
      <Menu.Item key="2">
        <SelectLanguage>{formatMessage({ id: 'global.navbar.lang_selector' })}</SelectLanguage>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        <Icon type="logout" /> {formatMessage({ id: 'global.logout' })}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={Overlay}
      placement="bottomRight"
      trigger={['click']}
      visible={dropdownVisible}
      onVisibleChange={(e) => setDropdownVisible(e)}
    >
      <Button icon="more" />
    </Dropdown>
  );
};
