import React, { useState, useEffect } from 'react';

import { Upload, Icon, Form, Select, Button } from 'antd';

import { connect } from 'react-redux';

import * as loginRedux from '@app/redux/models/login';

import * as globalCfg from '@app/configs/global';
import * as utils from '@app/utils/utils';

import * as form_helper from '@app/components/Form/form_helper';
import * as pixivus from '@app/services/pixivus';

import { injectIntl } from 'react-intl';

import _ from 'lodash';

const { Option } = Select;

var __formValuesChanged = null;
const BucketsFilter = (props) => {
  const [buttonType, setButtonType] = useState('default');
  const [key, setKey] = useState(props.the_key);
  const [is_loading, setIsLoading] = useState(props.isOperationsLoading || false);
  const [hidden_fields, setHiddenFields] = useState([]);
  const [intl, setIntl] = useState({});
  const [as_page, setAs_page] = useState(props.as_page);

  useEffect(() => {
    setAs_page(props.as_page);
  }, [props.as_page]);

  const default_filter = {
    date_range: [null, null],
    // , account_type:   undefined
    // , state:          undefined
    // , text_search:    ''
    // , in_out:         undefined
  };
  const [filter, setFilter] = useState(props.filter || default_filter);

  const { formatMessage } = props.intl;

  useEffect(() => {
    const myIntl = {};
    // myIntl.type_charge_pap = formatMessage({id:'requests.types.type_charge_pap'});
    myIntl.read = formatMessage({ id: 'global.bucket_permission.read' });
    myIntl.write = formatMessage({ id: 'global.bucket_permission.write' });
    myIntl.admin = formatMessage({ id: 'global.bucket_permission.admin' });

    myIntl.field_bucket = formatMessage({ id: 'components.filter.posts.bucket' });
    myIntl.field_user = formatMessage({ id: 'components.filter.posts.user' });
    myIntl.field_date_range = formatMessage({ id: 'components.filter.posts.date_range' });
    myIntl.field_post_type = formatMessage({ id: 'components.filter.posts.post_type' });
    myIntl.field_account_type = formatMessage({ id: 'components.filter.posts.account_type' });
    myIntl.field_account_role = formatMessage({ id: 'components.filter.posts.account_role' });
    myIntl.field_full_text_search = formatMessage({
      id: 'components.filter.posts.full_text_search',
    });

    myIntl.action_button_by_file = formatMessage({
      id: 'components.filter.posts.action_button_by_file',
    });

    setIntl(myIntl);
  }, [formatMessage]);

  useEffect(() => {
    if (!Array.isArray(props.hidden_fields)) return;
    setHiddenFields(props.hidden_fields || []);
  }, [props.hidden_fields]);

  const resetFilter = (e) => {
    e.preventDefault();
    props.form.resetFields();
    fireEvent(null, null, {});
  };

  const formValuesChanged = () => {
    setButtonType('primary');
  };
  __formValuesChanged = formValuesChanged;

  const applyFilter = (e) => {
    e.preventDefault();

    props.form.validateFields(async (err, values) => {
      if (err) {
        fireEvent(err, null, null);
        console.log(' ERRORS!! >> ', err);
        return;
      }

      // let filtered = {...values, 'requested_type' : values['requested_type'] && values['requested_type'].join(',')}
      let filtered = { ...values };
      const date_range = values['date_range'];
      if (date_range && date_range[0] && date_range[1] && !hidden_fields.includes('date_range'))
        filtered = { ...filtered, date_from: date_range[0], date_to: date_range[1] };
      if (filtered.date_range) delete filtered.date_range;

      // limpiamos las keys que estan vacias
      const filtered_nn = _.reduce(
        filtered,
        function (result, value, key) {
          if (value) result[key] = value;
          return result;
        },
        {}
      );

      fireEvent(null, null, filtered_nn, null);
    });
  };

  const fireEvent = (error, cancel, data, refresh) => {
    setButtonType('default');
    if (typeof props.callback === 'function') {
      console.log('************** filter is firing!!!!!!!!!!!!!', data);
      props.callback(error, cancel, data, refresh);
    }
  };

  const reload = () => {
    fireEvent(null, null, null, true);
  };

  const permissionTypesOptions = () => {
    return Object.keys(globalCfg.pixivus.buckets_permissions).map((perm) => {
      return (
        <Option key={'option' + perm.description} value={perm.value} label={intl[perm.description]}>
          {intl[perm.description]}{' '}
        </Option>
      );
    });
  };
  //
  const dropdownRender = (menu) => <div style={{ minWidth: 250 }}>{menu}</div>;
  //
  const className = `filter_form ${buttonType}`;
  const _form = props.form;

  if (!filter) return null;

  const _form_layout = as_page ? 'vertical' : 'inline';

  return (
    <Form
      layout={_form_layout}
      className={className}
      onSubmit={applyFilter}
      onChange={formValuesChanged}
    >
      <div style={{ height: 60 }}>
        {!hidden_fields.includes('text_search') &&
          form_helper.getSearchItem(
            _form,
            filter,
            'text_search',
            intl.field_full_text_search,
            intl.field_full_text_search,
            undefined,
            undefined
          )}
      </div>
      {false &&
        form_helper.getSelectItem(
          _form,
          filter,
          'permission_type',
          permissionTypesOptions(),
          intl.field_account_role,
          intl.field_account_role,
          'multiple',
          dropdownRender,
          undefined,
          true
        )}

      <div className={`filter_buttons ${_form_layout}`}>
        <Button disabled={is_loading} onClick={(event) => resetFilter(event)}>
          {formatMessage({ id: 'components.filters.reset' })}
        </Button>
        <Button htmlType="submit" disabled={is_loading} type={buttonType} style={{ marginLeft: 8 }}>
          {formatMessage({ id: 'components.filters.filter' })}
        </Button>
        <Button
          className="hidden"
          size="small"
          key="requests_refresh"
          icon="redo"
          disabled={is_loading}
          onClick={() => reload()}
        ></Button>
      </div>
    </Form>
  );
};
//
export default Form.create({
  onValuesChange: (props, changeValues, allValues) => {
    if (typeof __formValuesChanged === 'function') {
      __formValuesChanged();
    }
  },
})(
  connect(
    (state) => ({
      isAdmin: loginRedux.isAdmin(state),
    }),
    (dispatch) => ({})
  )(injectIntl(BucketsFilter))
);
