import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginRedux from '@app/redux/models/login';
import * as menuRedux from '@app/redux/models/menu';
import * as siteRedux from '@app/redux/models/site';
import * as bucketRedux from '@app/redux/models/buckets';

import * as globalCfg from '@app/configs/global';

import * as utils from '@app/utils/utils';

import { withRouter } from 'react-router-dom';
import * as routesService from '@app/services/routes';
import * as components_helper from '@app/components/helper';
import * as form_helper from '@app/components/Form/form_helper';
import * as pixivus from '@app/services/pixivus';

import { Select, PageHeader, Button, Spin, Modal, Form, Input } from 'antd';
import BucketsTags from '@app/components/buckets/tags';
import TxResult from '@app/components/TxResult';
import { RESET_PAGE, RESET_RESULT, DASHBOARD } from '@app/components/TxResult';
import AutocompleteAccount from '@app/components/AutocompleteAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AccountSelector from '@app/components/posts/account_selector';

import { FormattedMessage, injectIntl } from 'react-intl';

import _ from 'lodash';
import { RemoteBucketSelect } from '../RemoteSelect';

export const VIEW_MODE_FULL = 'view_mode_full';
export const VIEW_MODE_PROFILE = 'view_mode_profile';
export const VIEW_MODE_USERS = 'view_mode_users';
export const VIEW_MODE_CATEGORIES = 'view_mode_categories';
export const VIEW_MODE_CATEGORIES_NO_EDITABLE = 'view_mode_categories_no_editable';
const { Option } = Select;
const DEFAULT_STATE = {
  categories: [],
  permissions: {
    write: [],
    admin: [],
    read: [],
  },
  _id: '',
  _type: '',
};

const DEFAULT_BUCKET_PROFILE = {
  permissions: [],
  bucket: {
    key: undefined,
    name: undefined,
    relationships: [],
    meta: [],
    categories: [],
  },
};
class BucketForm extends Component {
  constructor(props) {
    super(props);

    const { categories, permissions, _id, _type } =
      this.loadBucket(props.bucket_profile) || DEFAULT_STATE;
    const bucketKey = props.match?.params?.bucketType;
    const bucketType = props.bucketTypes?.find((x) => x.key === bucketKey || x.key === _type) || {};

    this.state = {
      isFetching: false,
      isLoading: false,
      loading_hint: '',
      intl: {},
      view_mode: props.view_mode || VIEW_MODE_FULL,
      bucket_profile: props.bucket_profile || DEFAULT_BUCKET_PROFILE,
      _id: _id,
      _type: _type,
      categories: categories,
      permissions: permissions,
      meta: '',
      currentBucketType: bucketType,
      relationships: props.bucket_profile?.bucket.relationships,
    };

    this.getFieldsByViewMode = this.getFieldsByViewMode.bind(this);
    this.tagsChanged = this.tagsChanged.bind(this);
    this.inputChangeCallback = this.inputChangeCallback.bind(this);
    this.permissionsChanged = this.permissionsChanged.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.intl !== this.props.intl) {
      this.setIntl();
    }
    let new_state = {};
    const bucketType = this.props.match?.params?.bucketType;
    const { _type } = this.loadBucket(this.props.bucket_profile) || DEFAULT_STATE;
    if (
      prevProps.match?.params?.bucketType !== bucketType ||
      prevProps.bucketTypes?.length !== this.props.bucketTypes?.length ||
      prevState._type !== _type
    ) {
      new_state = {
        ...new_state,
        currentBucketType:
          this.props.bucketTypes?.find((type) => type.key === bucketType || type.key === _type) ||
          {},
      };
    }
    if (this.state.view_mode != this.props.view_mode) {
      new_state = { ...new_state, view_mode: this.props.view_mode };
    }
    if (
      !utils.objectsEqual(this.state.bucket_profile, this.props.bucket_profile) &&
      this.props.bucket_profile != null
    ) {
      const _bucket = this.loadBucket(this.props.bucket_profile);
      new_state = {
        ...new_state,
        bucket_profile: this.props.bucket_profile,
        ..._bucket,
        relationships: this.props.bucket_profile?.bucket.relationships,
      };
    }
    if (Object.keys(new_state).length > 0) this.setState(new_state);
  }

  componentDidMount() {
    this.setIntl();
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(this);
    }
  }
  setIntl() {
    const { formatMessage } = this.props.intl;
    let _intl = {
      meta: {},
    };

    _intl.read_permission_accounts = formatMessage({
      id: 'pages.new_bucket.read_permission_accounts',
    });
    _intl.write_permission_accounts = formatMessage({
      id: 'pages.new_bucket.write_permission_accounts',
    });
    _intl.admin_permission_accounts = formatMessage({
      id: 'pages.new_bucket.admin_permission_accounts',
    });

    _intl.field_name = formatMessage({ id: 'pages.new_bucket.field_name' });
    _intl.field_name_message = formatMessage({ id: 'pages.new_bucket.field_name_message' });
    _intl.field_validation_name = formatMessage({ id: 'pages.new_bucket.field_validation_name' });

    _intl.field_description = formatMessage({ id: 'pages.new_bucket.field_description' });
    _intl.field_description_message = formatMessage({
      id: 'pages.new_bucket.field_description_message',
    });
    _intl.field_validation_description = formatMessage({
      id: 'pages.new_bucket.field_validation_description',
    });

    _intl.field_categories = formatMessage({ id: 'pages.new_bucket.field_categories' });
    _intl.field_categories_message = formatMessage({
      id: 'pages.new_bucket.field_categories_message',
    });
    _intl.field_validation_categories = formatMessage({
      id: 'pages.new_bucket.field_validation_categories',
    });

    _intl.errors_cant_form_validation = formatMessage({ id: 'errors.cant_form_validation' });
    _intl.message_success_bucket_creation = formatMessage({
      id: 'pages.new_bucket.message_success_bucket_creation',
    });
    _intl.validation_type_category = formatMessage({
      id: 'pages.new_bucket.validation_type_category',
    });

    _intl.type = formatMessage({ id: 'global.buckets.type' });

    _intl.bucket_type_validation = formatMessage({ id: 'pages.new_bucket.validation_type_type' });
    _intl.bucket_type = formatMessage({ id: 'global.bucket_type' });

    this.setState({ intl: _intl });
  }

  loadBucket = (param) => {
    const bucket_profile = param;
    if (!bucket_profile || !bucket_profile.bucket) return null;

    const { bucket, permissions } = bucket_profile;
    return {
      _id: bucket._id,
      _type: bucket._type,
      categories: bucket.categories.map((cat) => cat.name),
      permissions: {
        write: permissions
          .filter((perm) => globalCfg.pixivus.isWritePerm(perm.permission))
          .map((perm) => perm.user.account_name),
        admin: permissions
          .filter((perm) => globalCfg.pixivus.isAdminPerm(perm.permission))
          .map((perm) => perm.user.account_name),
        read: permissions
          .filter((perm) => globalCfg.pixivus.isReadPerm(perm.permission))
          .map((perm) => perm.user.account_name),
      },
    };
  };

  componentWillUnmount() {
    if (typeof this.props.onRef === 'function') this.props.onRef(undefined);
  }

  /*
   * Components' Events.
   */

  fireEvent = (error, cancel, data, change) => {
    const { callback } = this.props;
    if (typeof callback === 'function') {
      callback(error, cancel, data, change);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        components_helper.notif.errorNotification(
          this.props.intl.formatMessage({ id: 'errors.validation_title' }),
          this.props.intl.formatMessage({ id: 'errors.verify_on_screen' })
        );
        console.log(' ERRORS!! >> ', err);
        return;
      }

      const {
        categories,
        permissions,
        bucket_profile,
        view_mode,
        currentBucketType,
        relationships,
      } = this.state;

      const shouldUpdateMeta = [VIEW_MODE_PROFILE, VIEW_MODE_FULL].includes(view_mode);
      const oldMeta =
        this.parseMeta(bucket_profile?.bucket?.meta) || bucket_profile?.bucket?.meta || '';
      let meta = shouldUpdateMeta ? values.meta : oldMeta;

      let formattedRelationships = [];
      if (currentBucketType.relationships) {
        currentBucketType.relationships.forEach(({ originKey }) => {
          const relationship = relationships?.find((x) => x.key === originKey);
          if (!relationship) {
            formattedRelationships.push({ key: originKey, buckets: [] });
          } else {
            formattedRelationships.push(relationship);
          }
        });
      }

      const { categoryOrder } = bucket_profile?.bucket;

      let bucket = {
        ...values,
        categories,
        permissions,
        categoryOrder,
        meta: JSON.stringify(meta),
        _type: this.state.currentBucketType.key,
        relationships: formattedRelationships,
      };

      if ((!categories || categories.length === 0) && !bucket._type) {
        components_helper.notif.errorNotification(this.state.intl.validation_type_category);
        return;
      }
      delete bucket.write_account;
      delete bucket.read_account;
      delete bucket.admin_account;

      if (bucket_profile && bucket_profile.bucket) bucket._id = bucket_profile.bucket._id;

      if (VIEW_MODE_PROFILE == view_mode) {
        delete bucket.permissions;
        delete bucket.categories;
      }
      if (VIEW_MODE_USERS == view_mode) {
        delete bucket.categories;
        delete bucket.name;
        delete bucket.description;
      }
      if (VIEW_MODE_CATEGORIES == view_mode || VIEW_MODE_CATEGORIES_NO_EDITABLE == view_mode) {
        delete bucket.permissions;
        delete bucket.name;
        delete bucket.description;
      }

      this.fireEvent(null, null, bucket, null);
    });
  };

  tagsChanged = (tags) => {
    let bucket_profile = this.state.bucket_profile;
    bucket_profile.bucket.categories = tags.map((tag) => {
      return { name: tag, description: tag };
    });
    this.setState({ bucket_profile: bucket_profile, categories: tags }, () => {
      this.changeCallback();
    });
  };

  permissionsArrayToObject = (permissions) => {
    const perms = _.reduce(
      permissions,
      function (result, value, key) {
        if (value)
          result = [
            ...result,
            ...value.map((account) => {
              return {
                user: { account_name: account },
                permission: globalCfg.pixivus.getPermission(key).value.toString(),
              };
            }),
          ];
        return result;
      },
      []
    );
    return perms;
  };

  relationshipsChanged = (key, buckets) => {
    let relationships =
      this.state.relationships ||
      this.state.currentBucketType.relationships.map(({ originKey }) => ({
        key: originKey,
        buckets: [],
      }));
    if (buckets && !Array.isArray(buckets)) {
      buckets = [buckets];
    }
    const formattedBuckets = buckets?.map(({ key }) => ({ _id: key })) || [];
    const relationship = relationships?.find((x) => x.key === key);
    if (relationship) {
      relationship.buckets = formattedBuckets;
    }
    this.setState({ relationships });
  };

  permissionsChanged = (key, perms) => {
    let permissions = this.state.permissions;
    permissions[key] = perms;

    let bucket_profile = this.state.bucket_profile;
    bucket_profile.permissions = this.permissionsArrayToObject(permissions);
    this.setState({ bucket_profile: bucket_profile }, () => {
      this.changeCallback();
    });
  };

  inputChangeCallback = (e, field) => {
    e.preventDefault();
    let bucket_profile = this.state.bucket_profile;
    bucket_profile.bucket[field] = e.target.value;
    this.setState({ bucket_profile: bucket_profile }, () => {
      this.changeCallback();
    });
  };

  getFieldsByViewMode = () => {
    switch (this.state.view_mode) {
      case VIEW_MODE_FULL:
        return {
          name: this.state.bucket_profile.bucket.name,
          description: this.state.bucket_profile.bucket.description,
          categories: this.state.bucket_profile.bucket.categories,
          permissions: this.state.bucket_profile.permissions,
          meta: this.state.bucket_profile.bucket.meta,
        };
      case VIEW_MODE_CATEGORIES:
      case VIEW_MODE_CATEGORIES_NO_EDITABLE:
        return { categories: this.state.bucket_profile.bucket.categories };
      case VIEW_MODE_PROFILE:
        return {
          name: this.state.bucket_profile.bucket.name,
          description: this.state.bucket_profile.bucket.description,
        };
      case VIEW_MODE_USERS:
        return { permissions: this.state.bucket_profile.permissions };
      default:
    }
  };

  parseMeta(metaString) {
    if (!metaString) return undefined;
    try {
      const metaParsed = JSON.parse(metaString);

      if (typeof metaParsed === 'object') {
        return metaParsed;
      }
    } catch (error) {
      console.log(`Error parsing meta: ${error}`);
    }
  }
  renderRelationships() {
    const { currentBucketType, relationships } = this.state;

    return (
      <>
        <FormattedMessage id="global.bucket.relationships.title" tagName="p" />
        {currentBucketType?.relationships?.map(({ originKey, multiple, bucketType }) => (
          <>
            <FormattedMessage id={`global.bucket.relationships.${originKey}`} tagName="p" />
            <RemoteBucketSelect
              key={originKey}
              callback={(buckets) => this.relationshipsChanged(originKey, buckets)}
              initialBuckets={relationships?.find((x) => x.key === originKey)?.buckets}
              placeholder={originKey}
              multiple={multiple}
              parameters={{ _type: bucketType, limit: 15 }}
            />
          </>
        ))}
      </>
    );
  }

  renderMetaInputs() {
    const { formatMessage } = this.props.intl;
    const _form = this.props.form;
    const { intl, bucket_profile, currentBucketType } = this.state;
    const { bucket } = bucket_profile || DEFAULT_BUCKET_PROFILE;
    const meta = typeof bucket.meta === 'string' && this.parseMeta(bucket.meta);
    if (meta) {
      bucket.meta = meta;
    }

    if (_.isEmpty(intl)) return null;

    return currentBucketType?.meta?.map(({ key, type, options }) => {
      const field = formatMessage({ id: `global.bucket.meta.${key}` });
      switch (type) {
        case 'text':
        case 'string':
          return form_helper.knoi(
            form_helper.getTextareaItem(
              _form,
              bucket,
              `meta.${key}`,
              field,
              `Missing ${field}`,
              false,
              this.inputChangeCallback
            ),
            field
          );
        case 'choice':
        case 'choice_multiple':
          const mode = type === 'choice_multiple' ? 'multiple' : 'default';
          return form_helper.knoi(
            form_helper.getSelectItem(
              _form,
              bucket,
              `meta.${key}`,
              this.metaOptions(options),
              field,
              field,
              mode,
              null,
              `Missing ${field}`,
              true
            ),
            field
          );
        case 'date':
          return form_helper.knoi(
            form_helper.getDateItem(_form, bucket, `meta.${key}`, field, `Missing ${field}`),
            field
          );

        default:
          return undefined;
      }
    });
  }

  changeCallback = () => {
    const bucket = this.getFieldsByViewMode();
    this.fireEvent(null, null, null, bucket);
  };

  reset() {
    this.props.form.resetFields();
  }

  metaOptions = (options = []) => {
    return options.map((opt) => {
      return (
        <Option key={'option_' + opt} value={opt} label={opt}>
          {opt}{' '}
        </Option>
      );
    });
  };
  //

  dropdownRender(menu) {
    return <div style={{ minWidth: 250 }}>{menu}</div>;
  }

  render() {
    const _form = this.props.form;
    const { formatMessage } = this.props.intl;
    const { view_mode, intl, isLoading, bucket_profile } = this.state;
    const { bucket } = bucket_profile || { bucket: {}, permissions: [] };
    const action = bucket_profile.bucket._id ? 'edit' : 'create';
    const button_text = formatMessage(
      {
        id: `pages.bucket.action_button_${action}_bucket`,
      },
      {
        bucketType: formatMessage({
          id: `global.bucket.${this.state.currentBucketType.key || ''}`,
        }),
      }
    );
    const _loading = isLoading;

    const allow_edit_categories = view_mode == VIEW_MODE_CATEGORIES_NO_EDITABLE ? false : true;
    const bucketForm = (
      <Form onSubmit={this.handleSubmit} className="knoi_form no_labels_form">
        <div className="money-transfer bucket_form">
          <div className="with_border_top"></div>

          {[VIEW_MODE_FULL, VIEW_MODE_PROFILE].includes(view_mode) &&
            form_helper.knoi(
              form_helper.getStringItem(
                _form,
                bucket,
                'name',
                intl.field_name,
                intl.field_validation_name,
                false,
                this.inputChangeCallback
              ),
              intl.field_name
            )}

          {[VIEW_MODE_FULL, VIEW_MODE_PROFILE].includes(view_mode) &&
            form_helper.knoi(
              form_helper.getTextareaItem(
                _form,
                bucket,
                'description',
                intl.field_description,
                intl.field_validation_description,
                false,
                this.inputChangeCallback
              ),
              intl.field_description
            )}

          {[VIEW_MODE_FULL, VIEW_MODE_PROFILE].includes(view_mode) && this.renderMetaInputs()}

          {[VIEW_MODE_FULL, VIEW_MODE_PROFILE].includes(view_mode) && this.renderRelationships()}

          {[VIEW_MODE_FULL, VIEW_MODE_USERS].includes(view_mode) && (
            <div className="">
              <h4>{this.state.intl.admin_permission_accounts}</h4>
              <AccountSelector
                callback={(perms) => this.permissionsChanged('admin', perms)}
                label={this.state.intl.admin_permission_accounts}
                form={_form}
                not_required={true}
                name="admin_account"
                without_icon={true}
                size="default"
                mode="multiple"
                tags={this.state.permissions.admin}
              />
            </div>
          )}

          {[VIEW_MODE_FULL, VIEW_MODE_USERS].includes(view_mode) && (
            <div className="bottom_borderedX">
              <h4>{this.state.intl.write_permission_accounts}</h4>
              <AccountSelector
                callback={(perms) => this.permissionsChanged('write', perms)}
                label={this.state.intl.write_permission_accounts}
                form={_form}
                not_required={true}
                name="write_account"
                without_icon={true}
                size="default"
                mode="multiple"
                tags={this.state.permissions.write}
              />
            </div>
          )}

          {[VIEW_MODE_FULL, VIEW_MODE_USERS].includes(view_mode) && (
            <div className="bottom_borderedX">
              <h4>{this.state.intl.read_permission_accounts}</h4>
              <AccountSelector
                callback={(perms) => this.permissionsChanged('read', perms)}
                label={this.state.intl.read_permission_accounts}
                form={_form}
                not_required={true}
                name="read_account"
                without_icon={true}
                size="default"
                mode="multiple"
                tags={this.state.permissions.read}
              />
            </div>
          )}

          {[VIEW_MODE_CATEGORIES, VIEW_MODE_CATEGORIES_NO_EDITABLE].includes(view_mode) && (
            <div className="money-transfer__row knoi row-complementary">
              <Form.Item label={this.state.intl.field_categories}>
                <BucketsTags
                  editable={allow_edit_categories}
                  form={_form}
                  tags={this.state.categories}
                  callback={this.tagsChanged}
                />
              </Form.Item>
            </div>
          )}
        </div>

        <div className="mp-box__actions mp-box__shore knoi">
          <Button
            size="large"
            key="doCreateBucket"
            htmlType="submit"
            type="primary"
            loading={_loading}
            disabled={view_mode === VIEW_MODE_CATEGORIES_NO_EDITABLE}
            icon="inbox"
          >
            {button_text}
          </Button>
        </div>
      </Form>
    );

    if (this.props.full) {
      return (
        <div style={{ margin: '0 0px', padding: 24, marginTop: 24 }}>
          <div className="ly-main-content content-spacing cards">
            <section className="mp-box mp-box__shadow money-transfer__box">
              <Spin spinning={_loading} delay={500} tip={this.state.intl.pushing_transaction}>
                {bucketForm}
              </Spin>
            </section>
          </div>
        </div>
      );
    }

    return bucketForm;
  }
}

export default Form.create()(
  withRouter(
    connect(
      (state) => ({
        isAdmin: loginRedux.isAdmin(state),
        isOwner: loginRedux.isOwner(state),
        accountDomain: loginRedux.accountDomain(state),
        bucketTypes: siteRedux.bucketTypes(state),
      }),
      (dispatch) => ({})
    )(injectIntl(BucketForm))
  )
);
