import React, { useState, useEffect } from 'react';

import { Tooltip, Tag, Icon, Form, Input, Button } from 'antd';

import { connect } from 'react-redux'

import * as loginRedux from '@app/redux/models/login'

import * as globalCfg from '@app/configs/global';
import * as utils from '@app/utils/utils';

import * as form_helper from '@app/components/Form/form_helper';
import * as pixivus from "@app/services/pixivus";

import { injectIntl } from "react-intl";

import _ from 'lodash';

const BucketsTags = (props) => {
    
    const [tags, setTags]                 = useState(props.tags);
    const [addNewText, setAddNewText]     = useState(props.add_new_text);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue]     = useState('');
    const [intl, setIntl]                 = useState({});
    const [editable, setEditable]         = useState(props.editable);

    const {formatMessage} = props.intl;


    let inputRef = null;
    // const inputRef = React.useRef(null);

    // useEffect(() => {
    //   setTags(props.tags);
    // }, [props.tags]);

    // let my_var = 0;
    // useEffect(() => {
    //   if(my_var!=0)
    //     fireChange();
    // }, [my_var]);

    useEffect(() => {
      setAddNewText(props.add_new_text)
    }, [props.add_new_text]);

    useEffect(() => {
      if(!utils.arraysEqual(tags, props.tags) )
        fireChange();
    }, [tags]);

    useEffect(() => {
      setEditable(props.editable);
    }, [props.editable]);

    useEffect(() => {
      if(!inputVisible)
        return;
      if(!inputRef)
        return;
      inputRef.focus();
    }, [inputVisible]);

    useEffect(() => {
      const myIntl = {}; 
      // myIntl.type_charge_pap = formatMessage({id:'requests.types.type_charge_pap'});
      myIntl.action_new_category = formatMessage({id:'pages.new_bucket.action_new_category'});
     setIntl(myIntl);
    }, []);

    const fireChange = () => {
      
      if(typeof props.callback != 'function')
      {
        console.log('TAGS::about-to-fire? NO')
        return;
      }
      console.log('TAGS::about-to-fire? YES!!!')
      props.callback(tags);
    };

    const handleClose = removedTag => {
      // console.log('TAGS::#1::handleClose()', editable);
      if(editable==false)
        return;
      // console.log('TAGS::#2::handleClose()::Setting tags');
      const _tags = tags.filter(tag => tag !== removedTag);
      setTags(_tags);
      // console.log('TAGS::#2::handleClose()::Setting tags', _tags);
      // fireChange();
      // my_var = Math.random();
    };

    const showInput = () => {
      setInputVisible(true);
    };

    const handleInputChange = e => {
      setInputValue(e.target.value );
    };

    const handleInputConfirm = (e) => {
      if(typeof e === 'object' && typeof e.preventDefault === 'function')
        e.preventDefault();
      let _tags = [...tags];
      if (inputValue && tags.indexOf(inputValue) === -1) {
        _tags = [..._tags, inputValue];
      }
      setTags(_tags);
      setInputVisible(false);
      setInputValue('');
      // fireChange();
      
    };

    // const saveInputRef = input => setInputRef( input);
    
    const can_edit = editable===false?false:true;
    // console.log('========can_edit?', can_edit)
    return(
      <div className="tagsContainer">
        {tags.map((tag, index) => {
          const isLongTag  = tag.length > 20;
          const tagElem = (
            <Tag key={tag} className="added_item" closable={can_edit} onClose={() => handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {can_edit && inputVisible && (
          <Input
            ref={(ref) => inputRef=ref} 
            type="text"
            size="small"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            className="bordered_input"
          />
        )}
        {can_edit && !inputVisible && (
          <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" /> {addNewText || intl.action_new_category}
          </Tag>
        )}
      </div>
    );
    //
}
//
export default 
   (connect((state)=> ({
      isAdmin:               loginRedux.isAdmin(state)

    }),
    (dispatch)=>({
      
    })
)( injectIntl(BucketsTags) ) );
