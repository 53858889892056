import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginRedux from '@app/redux/models/login';
import * as menuRedux from '@app/redux/models/menu';
import * as bucketRedux from '@app/redux/models/buckets';
import * as siteRedux from '@app/redux/models/site';

import * as utils from '@app/utils/utils';

import { withRouter } from 'react-router-dom';
import * as routesService from '@app/services/routes';
import * as components_helper from '@app/components/helper';

import { PageHeader, Spin, Form } from 'antd';
import TxResult from '@app/components/TxResult';
import { RESET_PAGE, RESET_RESULT, DASHBOARD } from '@app/components/TxResult';

import { injectIntl } from 'react-intl';
import Skeleton from '@app/components/Views/skeleton';
import BucketForm, { VIEW_MODE_FULL } from '@app/components/buckets/form';

class CreateBucket extends Component {
  constructor(props) {
    super(props);

    const bucketKey = props.match?.params?.bucketType;

    this.state = {
      routes: routesService.breadcrumbForPaths(props.location.pathname),
      isPushing: props.isPushing,
      isLoading: false,
      loading_hint: '',
      intl: {},
      result: props.result,
      isMobile: props.isMobile,
      currentBucketType: props.bucketTypes?.find((x) => x.key === bucketKey) || {},
    };

    this.formCallback = this.formCallback.bind(this);
    this.formRef = null;
  }
  setIntl() {
    const { formatMessage } = this.props.intl;

    let _intl = {};
    _intl.title = formatMessage(
      { id: 'pages.new_bucket.title' },
      {
        bucketType: formatMessage({
          id: `global.bucket.${this.state.currentBucketType.key || ''}`,
        }),
      }
    );

    _intl.read_permission_accounts = formatMessage({
      id: 'pages.new_bucket.read_permission_accounts',
    });
    _intl.write_permission_accounts = formatMessage({
      id: 'pages.new_bucket.write_permission_accounts',
    });
    _intl.admin_permission_accounts = formatMessage({
      id: 'pages.new_bucket.admin_permission_accounts',
    });

    _intl.field_name = formatMessage({ id: 'pages.new_bucket.field_name' });
    _intl.field_name_message = formatMessage({ id: 'pages.new_bucket.field_name_message' });
    _intl.field_validation_name = formatMessage({ id: 'pages.new_bucket.field_validation_name' });
    _intl.field_description = formatMessage({ id: 'pages.new_bucket.field_description' });
    _intl.field_description_message = formatMessage({
      id: 'pages.new_bucket.field_description_message',
    });
    _intl.field_validation_description = formatMessage({
      id: 'pages.new_bucket.field_validation_description',
    });

    _intl.field_categories = formatMessage({ id: 'pages.new_bucket.field_categories' });
    _intl.field_categories_message = formatMessage({
      id: 'pages.new_bucket.field_categories_message',
    });
    _intl.field_validation_categories = formatMessage({
      id: 'pages.new_bucket.field_validation_categories',
    });

    _intl.action_button_create_bucket = formatMessage({
      id: `pages.new_bucket.action_button_create_bucket`,
    });

    _intl.errors_cant_form_validation = formatMessage({ id: 'errors.cant_form_validation' });
    _intl.message_success_bucket_creation = formatMessage({
      id: 'pages.new_bucket.message_success_bucket_creation',
    });
    _intl.validation_type_category = formatMessage({
      id: 'pages.new_bucket.validation_type_category',
    });

    this.props.setCurrentPage({ title: _intl.title, backButton: null, showMenu: true });

    this.setState({ intl: _intl });
  }

  componentDidMount() {
    this.setIntl();
  }

  componentDidUpdate(prevProps, prevState) {
    let new_state = {};
    if (prevProps.intl !== this.props.intl) {
      this.setIntl();
    }

    const bucketType = this.props.match?.params?.bucketType;
    if (
      prevProps.match?.params?.bucketType !== bucketType ||
      prevProps.bucketTypes?.length !== this.props.bucketTypes?.length
    ) {
      // if bucketType charges or bucketTypes gets updated
      new_state = {
        ...new_state,
        currentBucketType: this.props.bucketTypes?.find((type) => type.key === bucketType) || {},
      };
    }

    if (this.state.isPushing != this.props.isPushing) {
      new_state = { ...new_state, isPushing: this.props.isPushing };
    }

    if (!utils.objectsEqual(this.state.result, this.props.result)) {
      const that = this;
      new_state = { ...new_state, result: this.props.result };
      setTimeout(() => {
        const result = this.props.result;
        if (result && result.status != 'success') {
          components_helper.notif.exceptionNotification(
            this.state.intl.error_cant_form_validation,
            result.object,
            this.props.clearResult
          );
          return;
        }
        if (result) {
          components_helper.notif.successNotification(
            this.state.intl.message_success_bucket_creation,
            this.props.clearResult
          );
        }
        that.reloadProfile();
      }, 100);
    }

    if (this.state.isMobile != this.props.isMobile) {
      new_state = { ...new_state, isMobile: this.props.isMobile };
    }

    if (Object.keys(new_state).length > 0) this.setState(new_state);
  }

  reloadProfile = () => {
    this.props.loadProfile(this.props.accountName);
  };

  backToDashboard = async () => {
    this.props.history.push({
      pathname: utils.homePath(this.props.accountType),
    });
  };

  resetResult() {
    this.props.clearResult();
    this.setState({ result: null });
  }

  resetPage() {
    this.props.clearResult();
    this.setState({ result: null });
    if (this.formRef) this.formRef.reset();
  }

  userResultEvent = (evt_type) => {
    if (evt_type == DASHBOARD) this.backToDashboard();
    if (evt_type == RESET_RESULT) this.resetResult();
    if (evt_type == RESET_PAGE) this.resetPage();
  };

  formCallback = (error, cancel, values, change) => {
    if (error) {
      return;
    }
    if (cancel) {
      this.resetPage();
      return;
    }

    if (change) return;
    this.props.createOrUpdate(values);
  };

  renderContent() {
    const { formatMessage } = this.props.intl;
    if (this.state.result != null) {
      const result = this.state.result;
      const result_type = result.status;
      const title = null;
      const message = null;
      const error = result.status == 'error' ? result.object : null;

      return (
        <div className="create_bucket_result">
          <TxResult
            button_go_to_text="Volver"
            result_type={result_type}
            title={title}
            message={message}
            error={error}
            cb={this.userResultEvent}
          />
        </div>
      );
    }

    const { isPushing, isLoading } = this.state;
    const _loading = isPushing || isLoading;
    return (
      <div className="create_bucket">
        <div className="ly-main-contentXXX content-spacingXXX cardsXXX">
          <section className="mp-main__content __configuration">
            <Spin spinning={_loading} delay={100} tip={this.state.intl.pushing_transaction}>
              <Skeleton
                title={formatMessage(
                  { id: 'pages.new_bucket.title' },
                  {
                    bucketType: formatMessage({
                      id: `global.bucket.${this.state.currentBucketType.key || ''}`,
                    }),
                  }
                )}
                simple_mode={true}
                icon="inbox"
                is_ant_icon={true}
                content={
                  <BucketForm
                    full={false}
                    bucket_profile={null}
                    callback={this.formCallback}
                    onRef={(ref) => (this.formRef = ref)}
                    view_mode={VIEW_MODE_FULL}
                  />
                }
              />
            </Spin>
          </section>
        </div>
      </div>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    let content = this.renderContent();
    const { isMobile } = this.state;

    const pager = isMobile ? null : (
      <PageHeader
        title={formatMessage(
          { id: 'pages.new_bucket.title' },
          {
            bucketType: formatMessage({
              id: `global.bucket.${this.state.currentBucketType.key || ''}`,
            }),
          }
        )}
        subTitle={this.state.intl.subtitle}
      />
    );

    return (
      <>
        {pager}
        {content}
      </>
    );
  }
}

export default Form.create()(
  withRouter(
    connect(
      (state) => ({
        accountName: loginRedux.accountName(state),
        accountType: loginRedux.accountType(state),
        accountBuckets: loginRedux.accountBuckets(state),
        isPushing: bucketRedux.isPushing(state),
        result: bucketRedux.result(state),
        isMobile: menuRedux.isMobile(state),
        bucketTypes: siteRedux.bucketTypes(state),
      }),
      (dispatch) => ({
        setCurrentPage: bindActionCreators(menuRedux.setCurrentPage, dispatch),
        setLastRootMenuFullpath: bindActionCreators(menuRedux.setLastRootMenuFullpath, dispatch),
        loadProfile: bindActionCreators(loginRedux.loadProfile, dispatch),
        createOrUpdate: bindActionCreators(bucketRedux.createOrUpdate, dispatch),
        clearResult: bindActionCreators(bucketRedux.clearResult, dispatch),
      })
    )(injectIntl(CreateBucket))
  )
);
